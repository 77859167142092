import BaseMutations from '@/store/base/mutations'
import * as Types from '@/store/mutationTypes'
import PersonMutations from '@/store/base/person/mutations'
import DocumentsMutations from '@/store/base/documents/mutations'
import DetailMutations from "@/store/base/detail/mutations";
import BlacklistMutations from "@/store/base/blacklist/mutations";
import TimekeepingMutations from "@/store/base/timekeeping/mutations";
import HistoriesMutations from "@/store/base/histories/mutations";
import moment from "moment";

const module = 'employees'

export default BaseMutations({
  // TODO: improve stateVariable usage
  [Types.ADD_TO_MODEL](state, data) {
    if(typeof data.stateVariable === 'string' && state[data.stateVariable]) {
      const stateVariable = data.stateVariable

      delete data.stateVariable

      state[stateVariable].unshift(data)
      return
    }

    state.module.unshift(data)
  },

  // TODO: improve stateVariable usage
  [Types.UPDATE_SINGLE_MODEL](state, data) {
    if(typeof data?.stateVariable !== 'undefined' && state[data.stateVariable]) {
      let index = state[data.stateVariable].findIndex(el => el.uuid === data.uuid)
      let stateVariable = data.stateVariable

      delete data.stateVariable

      if(state?.[stateVariable]?.[index])
        state[stateVariable][index] = data
      return
    }

    let index = state.module.findIndex(el => el[state.primaryKey] === data[state.primaryKey])
    if(0 <= index)
      state.module[index] = data
  },

  // TODO: improve stateVariable usage
  [Types.SINGLE_DELETE_FROM_MODAL](state, data) {
    if(typeof data?.stateVariable !== 'undefined' && state[data.stateVariable]) {
      state[data.stateVariable] = state[data.stateVariable].filter(model => model.uuid !== data.uuid)
      return
    }

    state.module = state.module.filter(employee => employee[state.primaryKey] !== data[state.primaryKey])
  },

  [Types.SET_CURRENT_MODULE_WAREHOUSE](state, warehouse) {
    state.currentModule.warehouse = warehouse
    state.currentModule.warehouseFetched = true
  },

  [Types.ADD_WAREHOUSE_TO_CURRENT_MODULE](state, warehouse) {
    if(!Array.isArray(state.currentModule.warehouse))
      state.currentModule.warehouse = []

    state.currentModule.warehouse.push(warehouse)
  },

  [Types.UPDATE_WAREHOUSE_IN_CURRENT_MODULE](state, data) {
    const toolIndex = state.currentModule.warehouse.findIndex(tool => tool.uuid === data.uuid)

    if(0 <= toolIndex && state.currentModule.warehouse?.[toolIndex])
      state.currentModule.warehouse[toolIndex] = data
  },

  [Types.REMOVE_WAREHOUSE_FROM_CURRENT_MODULE](state, uuid) {
    const moduleIndex = state.currentModule.warehouse.findIndex(warehouse => warehouse.uuid === uuid)

    if(0 <= moduleIndex)
      state.currentModule.warehouse.splice(moduleIndex, 1)
  },

  [Types.UPDATE_CURRENT_MODULE_WAREHOUSE](state, data) {
    if(!Array.isArray(state?.currentModule?.warehouse))
      return

    let moduleIndex = state.currentModule.warehouse.findIndex(warehouse => warehouse.pivot_id === data.pivot_id)

    if(0 <= moduleIndex)
      state.currentModule.warehouse[moduleIndex] = data
  },

  [Types.ADD_HIRED_CANDIDATE](state, hiredCandidate) {
    if(state.isFetched)
      state.module.unshift(hiredCandidate)
  },

  [Types.FIRE_EMPLOYEE](state, firedEmployee) {
    const moduleIndex = state.module.findIndex(module => module.uuid === firedEmployee.uuid)

    if(0 <= moduleIndex)
      state.module.splice(moduleIndex, 1)
  },

  [Types.ADD_PROJECT_TO_EMPLOYEE](state, employee) {
    const employeeIndex = state.module.findIndex(el => el.uuid === employee.uuid)

    if(0 <= employeeIndex)
      state.module[employeeIndex] = employee
  },

  [Types.SET_CURRENT_MODULE_ACCOMMODATION](state, accommodation) {
    state.currentModule.accommodation = accommodation
    state.currentModule.accommodationFetched = true
  },

  [Types.SET_CURRENT_MODULE_TRANSPORT](state, transport) {
    state.currentModule.transport = transport
    state.currentModule.transportFetched = true
  },

  [Types.SET_CURRENT_MODULE_TIMEKEEPING_TEMPLATE](state, template) {
    state.currentModule.timekeepingTemplate = template
    state.currentModule.timekeepingTemplateFetched = true
  },

  [Types.REMOVE_PROJECT_FROM_EMPLOYEE](state, employee) {
    const employeeIndex = state.module.findIndex(el => el.uuid === employee.uuid)

    if(0 <= employeeIndex)
      state.module[employeeIndex] = employee
  },

  [Types.REMOVE_ACCOMMODATION_FROM_CURRENT_MODULE](state) {
    state.currentModule.accommodation = {}
    state.currentModule.accommodation_starts_at = null
    state.currentModule.accommodation_ends_at = null
  },

  [Types.REMOVE_TRANSPORT_FROM_CURRENT_MODULE](state) {
    state.currentModule.transport = {}
    state.currentModule.travel_starts_at = null
    state.currentModule.travel_ends_at = null
  },

  [Types.SET_TIMEKEEPING_DAYS](state, timekeepingDays) {
    state.timekeepingDays = timekeepingDays
  },

  [Types.UNSET_TIMEKEEPING_DAYS](state) {
    state.timekeepingDays = []
  },

  [Types.SET_SCHEDULE_YEAR](state, year) {
    state.currentModule.scheduleYear = year
  },

  [Types.SET_SCHEDULE_MONTH](state, month) {
    state.currentModule.scheduleMonth = month
  },

  [Types.CLEAR_SINGLE_MULTIPLE_FORM_ERRORS](state, formIndex) {
    Object.keys(state.scheduleErrors).forEach(errorKey => {
      if(errorKey.startsWith(`days.${formIndex}.`))
        delete state.scheduleErrors[errorKey]
    })
  },

  [Types.APPEND_SCHEDULE_DAYS_ERRORS](state, errors) {
    Object.keys(errors).forEach(errorKey => {
      if(!Array.isArray(state.scheduleErrors[errorKey]))
        state.scheduleErrors[errorKey] = []

      const existingErrors = [...state.scheduleErrors[errorKey]]

      state.scheduleErrors[errorKey] = [...new Set([...existingErrors, ...errors[errorKey]])]
    })
  },

  [Types.EXPORTING_EMPLOYEE_SCHEDULE](state, status) {
    state.currentModule.exportingSchedule = !!status
  },

  [Types.SET_ROTATION_TABLE_DATE](state, {date, push = true}) {
    const momentJsDate = moment(date)

    if(!momentJsDate.isValid())
      return

    state.rotation_table.months?.[push ? 'push' : 'unshift']({
      year: momentJsDate.year(),
      month: momentJsDate.month() + 1,
      dateFormatted: momentJsDate.locale('en').format('MMMM YYYY'),
      date: momentJsDate.format('YYYY-MM'),
      days: momentJsDate.daysInMonth(),
    })
  },

  [Types.EXPORTING_ROTATION_TABLE](state, status) {
    state.rotation_table.exporting = !!status
  },

  [Types.EXPORTING_EMPLOYEE_WAREHOUSE](state, status) {
    state.exportingTools = !!status
  },

  [Types.INITIATE_ROTATION_TABLE](state) {
    state.showSearchResults = true

    state.moduleSearchResults = state.module.filter(({object_uuid}) => object_uuid)
  },

  [Types.UNSET_ROTATION_TABLE](state) {
    state.showSearchResults = false

    state.moduleSearchResults = []
  },

  [Types.FILL_GENERATE_DOCUMENTS_FORM_DATA](state, uuid) {
    let data = undefined

    if(typeof state?.currentModule?.uuid === 'string' && state.currentModule.uuid.length && state.currentModule.uuid === uuid)
      data = state.currentModule

    if(!data)
      data = state.module.find(el => el.uuid === uuid)

    if(data)
      Object.keys(state.generateDocumentsFormData).forEach(key => {
        /* eslint-disable no-case-declarations */
        switch (key) {
          case 'department':
            state.generateDocumentsFormData[key] = state.departments.find(department => data[key] === department.slug)?.name_lt || ''
            break

          case 'contract_type':
            const name = state.contractTypes.find(contractType => data[key] === contractType.slug)?.name_lt
            state.generateDocumentsFormData[key] = (name || '').toLowerCase()
            break

          case 'work_hours_per_week':
            state.generateDocumentsFormData[key] = `${data[key]} valandos per savaitę`
            break

          default:
            state.generateDocumentsFormData[key] = data[key]
        }
      })
  },
}, [PersonMutations, DocumentsMutations(module), BlacklistMutations({module}), DetailMutations({module}), TimekeepingMutations({module}), HistoriesMutations({module})])
