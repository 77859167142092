import BaseMutations from '@/store/base/mutations'
import DocumentsMutations from '@/store/base/documents/mutations'
import DetailMutations from "@/store/base/detail/mutations";
import * as Types from "@/store/mutationTypes"

const module = 'warehouse'

export default BaseMutations({
    [Types.ASSIGN_WAREHOUSE](state, data) {
      const index = state.module.findIndex(warehouse => warehouse.uuid === data?.uuid)

      if(0 <= index)
          state.module[index].assigned_to = data.assigned_to
    },

    [Types.UNASSIGN_WAREHOUSE](state, data) {
      const index = state.module.findIndex(warehouse => warehouse.uuid === data?.uuid)

      if(0 <= index)
          state.module[index].assigned_to = null
    },

    [Types.ADD_EMPLOYEE_TO_TOOL](state, tool) {
      const toolIndex = state.module.findIndex(el => el.uuid === tool.uuid)

      if(0 <= toolIndex)
        state.module[toolIndex] = tool
    },

    [Types.REMOVE_EMPLOYEE_FROM_TOOL](state, tool) {
      const toolIndex = state.module.findIndex(el => el.uuid === tool.uuid)

      if(0 <= toolIndex)
        state.module[toolIndex] = tool
    },

    [Types.UPDATE_CURRENT_TOOL](state, data) {
      state.currentModule = data
    }
}, [DocumentsMutations(module), DetailMutations({module})])
