import BaseGetters from '@/store/base/getters'
import PersonGetters from '@/store/base/person/getters'
import DocumentsGetters from '@/store/base/documents/getters'
import DetailGetters from '@/store/base/detail/getters'
import BlacklistGetters from '@/store/base/blacklist/getters'
import TimekeepingGetters from '@/store/base/timekeeping/getters'
import moment from "moment";
import HistoriesGetters from "@/store/base/histories/getters";
/* eslint-disable */
import {
  addPad,
  formatDuration,
  isFutureDate,
  isPresentDate,
  dateBetweenDates,
  normalizeDate,
  isPastDate,
  convertTimeToMinutes,
  getTimeDuration,
  getFutureMoment,
  dateOverlap
} from "@/helpers/formatters";

const model = 'employees'

export default BaseGetters({
  getStatuses: state => state.statuses,
  getStatusTitle: (state, getters) => statusSlug => getters['getStatuses'].find(status => status.slug === statusSlug)?.name || '',
  getDepartments: state => state.departments,
  getContractTypes: state => state.contractTypes,
  getDocumentTypes: state => state.documentTypes,
  getGenders: state => state.genders,
  getCurrentModule: state => state.currentModule,
  getCompanyTitle: (state, getters, rootState, rootGetters) => uuid => rootGetters['companies/getModule'].find(company => company.uuid === uuid)?.title || '',
  getDepartmentTitle: (state, getters) => departmentSlug => getters['getDepartments'].find(department => department.slug === departmentSlug)?.name || '',
  getContractTypeTitle: (state, getters) => contractTypeSlug => getters['getContractTypes'].find(contractType => contractType.slug === contractTypeSlug)?.name || '',
  getDocumentTypeTitle: (state, getters) => documentTypeSlug => getters['getDocumentTypes'].find(documentType => documentType.slug === documentTypeSlug)?.name || '',
  getGenderTitle: (state, getters) => genderSlug => getters['getGenders'].find(gender => gender.slug === genderSlug)?.name || '',
  getProfileUuid: (state, getters) => getters['getCurrentModule']?.uuid,
  currentModule: state => state.currentModule,
  getWarehouse: state => state.currentModule?.warehouse || [],
  getWarehouseFetched: state => state.currentModule?.warehouseFetched,
  getWarehouseToolByPivotId: state => pivot_id => state.currentModule.warehouse.find(el => el.pivot_id === pivot_id),
  getAvailableWarehouse: (state, getters, rootState, rootGetters) => rootGetters['warehouse/getModule'].filter(warehouse => !warehouse.assigned_to),
  getCurrentModuleTitle: (state, getters) => getters['getCurrentModule'][getters['getNotificationKey']],
  getCurrentModuleAccommodation: state => state.currentModule.accommodation ?? {},
  getCurrentModuleAccommodationFetched: state => state.currentModule.accommodationFetched,
  getCurrentModuleTransport: state => state.currentModule.transport ?? {},
  getCurrentModuleTransportFetched: state => state.currentModule.transportFetched,
  getCurrentModuleTrainings: (state, getters, rootState, rootGetters) => rootGetters['employeeTrainings/getModule'] ?? [],
  getCurrentModuleTrainingsFetched: (state, getters, rootState, rootGetters) => rootGetters['employeeTrainings/isFetched'],
  getTimekeepingTemplate: (state, getters, rootState, rootGetters) => rootGetters['employeeTimekeepingTemplates/getTimekeepingTemplate'](getters['getCurrentModuleUuid']),
  getTimekeepingTemplateDays: (state, getters) => getters['getTimekeepingTemplate'].days,
  getTimekeepingTemplateWeekday: (state, getters) => weekdayIndex => {
    const weekdayKey = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ][weekdayIndex - 1]

    return getters['getTimekeepingTemplateDays'][weekdayKey]
  },
  getTimekeepingTemplateFetched: state => state.currentModule.timekeepingTemplateFetched,
  getScheduleYear: state => state.currentModule.scheduleYear,
  getScheduleMonth: state => state.currentModule.scheduleMonth,
  getTimekeepingDays: (state) => state.timekeepingDays,
  getTimekeepingDay: (state, getters, rootState, rootGetters) => (day, weekdayIndex, employeeUuid = getters['getCurrentModuleUuid']) => {
    const { schedule_starts_at, schedule_ends_at, days } = rootGetters['employeeTimekeepingTemplates/getTimekeepingTemplate'](employeeUuid) ?? {}

    if(isNaN(day) || !days)
      return undefined

    let timekeepingDay = rootGetters['timekeepingDays/getDay'](employeeUuid, state.currentModule.scheduleYear, state.currentModule.scheduleMonth, day)

    if(!timekeepingDay && dateBetweenDates(`${state.currentModule.scheduleYear}-${addPad(state.currentModule.scheduleMonth)}-${addPad(day)}`, schedule_starts_at, schedule_ends_at)) {
      const weekday = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ][weekdayIndex - 1]

      timekeepingDay = days[weekday]
    }

    return timekeepingDay || undefined
  },
  getDayData: (state, getters) => (day, weekday, employeeUuid) => getters['getTimekeepingDay'](day, weekday, employeeUuid),
  getAbsenceDays: (state, getters) => (absence, employeeUuid) => {
    const scheduleYearMonth = `${state.currentModule.scheduleYear}-${addPad(state.currentModule.scheduleMonth)}`

    if(isFutureDate(scheduleYearMonth, 'month'))
      return []

    return getters['getTimekeepingDays'].filter(timekeepingDay => {
      const dayDate = `${scheduleYearMonth}-${addPad(timekeepingDay.day)}`

      if(
        isFutureDate(dayDate) ||
        isPresentDate(dayDate, 'day')
      )
        return false

      return getters['getTimekeepingDay'](timekeepingDay.day, timekeepingDay.weekday, employeeUuid)?.absence === absence.slug
    })
  },
  getAbsenceDaysCount: (state, getters) => (absence, employeeUuid = getters['getCurrentModuleUuid']) => getters['getAbsenceDays'](absence, employeeUuid).length,
  getAbsenceHours: (state, getters) => (absence, employeeUuid = getters['getCurrentModuleUuid']) => {
    let workTimeMinutes = 0

    getters['getAbsenceDays'](absence, employeeUuid).forEach(absenceDay => {
      const workTime = moment(getters['getDayWorkHours'](absenceDay.day, absenceDay.weekday), 'HH:mm')
      workTimeMinutes += workTime.hours() * 60 + workTime.minutes()
    })

    return Math.floor(workTimeMinutes / 60)
  },
  getScheduleErrors: (state) => state?.scheduleErrors || {},
  getScheduleFieldsErrors: (state, getters, rootState, rootGetters) => (dayIndex, property) => {
    const formErrors = getters['getScheduleErrors'][`days.${dayIndex}.${property}`] || []
    const formFieldsErrors = rootGetters['form/getFormFieldsErrors'][`days.${dayIndex}.${property}`] || []

    return [...formFieldsErrors, ...formErrors]
  },
  getScheduleErrorsForDisplay: (state, getters) => {
    const displayErrors = []

    for (const [errorKey, error] of Object.entries(getters['getScheduleErrors'])) {
      displayErrors.push(...error)

      if(2 <= displayErrors.length)
        return [displayErrors[0], displayErrors[1]]
    }

    return displayErrors
  },
  isCurrentScheduleApproved: (state, getters, rootState, rootGetters) => {
    const employeeUuid = rootGetters['employees/getCurrentModuleUuid']
    const timekeepingTemplateUuid = rootGetters['employeeTimekeepingTemplates/getTimekeepingTemplate'](employeeUuid)?.uuid

    return !!rootGetters['employeeSchedules/findScheduleByDuration'](employeeUuid, timekeepingTemplateUuid, getters['getScheduleYear'], getters['getScheduleMonth'])?.approved
  },
  isExportingSchedule: ({currentModule}) => currentModule.exportingSchedule || false,
  isEmptyDay: (state, getters) => (day, weekday) => {
    const timekeepingDay = getters['getTimekeepingDay'](day, weekday)

    return !timekeepingDay || ['work_starts_at', 'work_ends_at', 'lunch_starts_at', 'lunch_ends_at'].every(key => !timekeepingDay[key])
  },
  actualScheduleWorkDays: ({currentModule}, getters) => (employeeUuid) => {
    return getters['getTimekeepingDays'].filter(({day, weekday}) => {
      const date = normalizeDate(currentModule.scheduleYear, currentModule.scheduleMonth, day)
      if(
        !isPastDate(date) ||
        isPresentDate(date, 'day')
      )
        return false

      const {work_total} = getters['getTimekeepingDay'](day, weekday, employeeUuid) ?? {}

      return work_total && work_total !== '00:00'
    }).length
  },
  actualScheduleWorkHours: ({currentModule}, getters) => (returnHours, employeeUuid) => {
    let minutes = 0

    getters['getTimekeepingDays'].forEach(({day, weekday}) => {
      const date = normalizeDate(currentModule.scheduleYear, currentModule.scheduleMonth, day)

      if(
        !isPastDate(date) ||
        isPresentDate(date, 'day')
      )
        return

      const {work_total} = getters['getTimekeepingDay'](day, weekday, employeeUuid) ?? {}

      minutes += work_total && work_total !== '00:00' ? convertTimeToMinutes(work_total) : 0
    })

    if(returnHours)
      return Math.floor(minutes / 60)

    return minutes <= 0 ? '00:00' : formatDuration(minutes, 'minutes')
  },
  actualNightWorkHours: ({currentModule}, getters) => (employeeUuid = getters['getCurrentModuleUuid']) => {
    const { nightShiftStartTime, nightShiftEndTime } = getters['getNightRate']()

    if(!nightShiftStartTime || !nightShiftEndTime)
      return 0

    let minutes = 0

    getters['getTimekeepingDays'].forEach(({day, weekday}) => {
      const date = normalizeDate(currentModule.scheduleYear, currentModule.scheduleMonth, day)
      if(
        !isPastDate(date) ||
        isPresentDate(date, 'day')
      )
        return

      const nightHours = getters['getNightWorkHours'](day, weekday, employeeUuid)

      minutes += nightHours && nightHours !== '00:00' ? convertTimeToMinutes(nightHours) : 0
    })

    return Math.floor(minutes / 60)
  },
  pastAbsencesDays: ({currentModule}, getters) => (employeeUuid, ...absences) => {
    return getters['getTimekeepingDays'].filter(({day, weekday}) => {
      if(isFutureDate(normalizeDate(currentModule.scheduleYear, currentModule.scheduleMonth, day), 'day', true))
        return false

      return [...absences].includes(getters['getTimekeepingDay'](day, weekday, employeeUuid)?.absence)
    }).length
  },
  pastAbsencesDuration: ({currentModule}, getters) => (employeeUuid, ...absences) => {
    let minutes = 0

    getters['getTimekeepingDays'].forEach(({day, weekday}) => {
      const date = normalizeDate(currentModule.scheduleYear, currentModule.scheduleMonth, day)

      // Calculate only past days, excluding current day
      if(isFutureDate(date, 'day', true))
        return

      const timekeepingDay = getters['getTimekeepingDay'](day, weekday, employeeUuid)

      // Check if timekeeping day has specified absence reason
      if(
        !timekeepingDay ||
        !timekeepingDay.work_total ||
        ![...absences].includes(timekeepingDay?.absence)
      )
        return

      minutes += timekeepingDay.work_total === '00:00' ? 0 : convertTimeToMinutes(timekeepingDay.work_total)
    })

    return Math.floor(minutes / 60)
  },
  actualDeviationsHours: (state, getters) => (employeeUuid) => {
    return getters['pastAbsencesDuration'](employeeUuid, 'ks')
  },
  actualDutyOnHomeHours: (state, getters) => (employeeUuid) => {
    return getters['pastAbsencesDuration'](employeeUuid, 'bn')
  },
  actualDutyOnWorkHours: (state, getters) => (employeeUuid) => {
    return getters['pastAbsencesDuration'](employeeUuid, 'bį')
  },
  actualRestHours: (state, getters) => (employeeUuid) => {
    return getters['pastAbsencesDuration'](employeeUuid, 'dp', 'v', 'm','p')
  },
  absencesFromWorkHours: (state, getters) => (employeeUuid) => {
    return getters['pastAbsencesDuration'](employeeUuid, ...getters['absencesFromWorkReasons'])
  },
  absencesFromWorkDays: (state, getters) => (employeeUuid) => {
    return getters['pastAbsencesDays'](employeeUuid, ...getters['absencesFromWorkReasons'])
  },
  isNightShift: (state, getters) => (day, weekday, employeeUuid = getters['getCurrentModuleUuid']) => {
    const nightWork = getters['getNightWorkHours'](day, weekday, employeeUuid)

    return nightWork && nightWork !== '00:00' && 0 < convertTimeToMinutes(nightWork)
  },
  workOvertime: (state, getters) => (employeeUuid, timekeepingTemplateUuid) => {
    const workOvertime = convertTimeToMinutes(getters['totalWork'](employeeUuid, timekeepingTemplateUuid)) - convertTimeToMinutes(getters['actualScheduleWorkHours'](false, employeeUuid))

    const hours = Math.floor(workOvertime / 60)

    return 0 < hours ? hours : 0
  },
  totalWork: ({currentModule}, getters, rootState, rootGetters) => (employeeUuid, timekeepingTemplateUuid) => {
    const year = getters['getScheduleYear']
    const month = getters['getScheduleMonth']

    const {determined_work_hours} = rootGetters['employeeSchedules/findScheduleByDuration'](employeeUuid, timekeepingTemplateUuid, year, month) || {}

    let minutes = (!isNaN(determined_work_hours) ? parseInt(determined_work_hours) : 0) * 60

    return minutes <= 0 ? '00:00' : formatDuration(minutes, 'minutes')
  },
  absencesFromWorkReasons: () => ['bn', 'id', 'md', 'v', 'm', 'd', 'l', 'n', 'ns', 'a', 'ma', 'na', 'ka', 'g', 'pv', 'kr', 'ta', 'mž', 'sž', 'kv', 'vv', 'kt', 'km', 'pk', 'pn', 'pb', 'nd', 'np', 'nn', 'st'],

  showRotationTable: ({showSearchResults}) => showSearchResults,
  isExportingRotationTable: ({rotation_table}) => !!rotation_table?.exporting,
  getRotationTableMonths: ({rotation_table}) => rotation_table.months,
  getRotationTableMonthsLength: ({rotation_table}) => rotation_table.months?.length || 0,
  sliceRotationTableNextMonth: ({rotation_table}) => (fromEnd) => {
    return rotation_table.months.slice(fromEnd ? -1 : 0, fromEnd ? undefined : 1)?.[0]
  },
  getRotationTable: ({moduleSearchResults}) => moduleSearchResults,
  isExportingTools: ({exportingTools}) => exportingTools,
  projectEmployees: ({module}) => (projectUuid) => module.filter(({object_uuid}) => projectUuid === object_uuid),
}, [PersonGetters({model}), DocumentsGetters, BlacklistGetters, DetailGetters, TimekeepingGetters, HistoriesGetters])
