import BaseMutations from '@/store/base/mutations'
import * as Types from '@/store/mutationTypes'
import ArchivesMutations from "@/store/base/archives/mutations";
import DocumentsMutations from "@/store/base/documents/mutations";
import DetailMutations from "@/store/base/detail/mutations";
import TimekeepingMutations from "@/store/base/timekeeping/mutations";
import { padStart } from "lodash";

const module = 'projects'

export default BaseMutations({
  [Types.EMPTY_ARCHIVE_FORM_DATA](state) {
    state.archiveFormData.uuid = ''
    state.archiveFormData.pending_amounts = null
    state.archiveFormData.warranty_period_starts_at = ''
    state.archiveFormData.warranty_period_ends_at = ''
    state.archiveFormData.bills_covered = false
  },

  [Types.ARCHIVE_MODULE](state, data) {
    const index = state.module['active']?.findIndex(module => module.uuid === data?.uuid)

    if(typeof state.module['active'][index] === 'object') {
      let moduleName = state.module['active'][index][state.notificationTitle]

      state.module['active'][index].bills_covered = data.bills_covered
      state.module['active'][index].pending_amounts = data.pending_amounts
      state.module['active'][index].warranty_period_starts_at = data.warranty_period_starts_at
      state.module['active'][index].warranty_period_ends_at = data.warranty_period_ends_at
      state.module['active'][index].is_archived = data.is_archived
      state.module['archived'].unshift(state.module['active'][index])
      state.module['active'].splice(index, 1)

      this.dispatch('notification/addNotificationToList', {
        type: 'success',
        message: `${state.moduleTitle} "${moduleName}" was archived.`,
        actions: {
          close: true,
        }
      }, { root: true })

      if(state.filtersBy.length)
        this.dispatch(`${module}/filter`, {}, {root: true})
    }
  },

  [Types.UNARCHIVE_MODULE](state, data) {
    const index = state.module['archived']?.findIndex(module => module.uuid === data?.uuid)

    if(typeof state.module['archived'][index] === 'object') {
      let moduleName = state.module['archived'][index][state.notificationTitle]

      state.module['archived'][index].bills_covered = data.bills_covered
      state.module['archived'][index].pending_amounts = data.pending_amounts
      state.module['archived'][index].warranty_period_starts_at = data.warranty_period_starts_at
      state.module['archived'][index].warranty_period_ends_at = data.warranty_period_ends_at
      state.module['archived'][index].is_archived = data.is_archived
      state.module['active'].unshift(state.module['archived'][index])
      state.module['archived'].splice(index, 1)

      this.dispatch('notification/addNotificationToList', {
        type: 'success',
        message: `${state.moduleTitle} "${moduleName}" was unarchived.`,
        actions: {
          close: true,
        }
      }, { root: true })

      if(state.filtersBy.length)
        this.dispatch(`${module}/filter`, {}, {root: true})
    }
  },

  [Types.FILL_FORM_DATA](state, uuid) {
    let module
    if(
      Array.isArray(state.module['archived']) &&
      Array.isArray(state.module['active'])
    )
      module = state.module['archived'].concat(state.module['active']).find(el => el.uuid === uuid)

    if(!module && state.currentModule?.uuid === uuid)
      module = state.currentModule

    state.formData.uuid = module.uuid
    state.formData.accounting_code = module.accounting_code
    state.formData.object_no = module.object_no
    state.formData.company_uuid = module.company_uuid
    state.formData.client = module.client
    state.formData.company_no = module.company_no
    state.formData.company_vat = module.company_vat
    state.formData.country_id = module.country_id
    state.formData.city_id = module.city_id
    state.formData.address = module.address
    state.formData.phone = module.phone
    state.formData.email = module.email
    state.formData.contact_person = module.contact_person
    state.formData.object_name = module.object_name
    state.formData.object_address = module.object_address
    state.formData.project_type = module.project_type
    state.formData.work_begins = module.work_begins
    state.formData.work_ends = module.work_ends
    state.formData.work_volume = module.work_volume
    state.formData.project_value = module.project_value
    state.formData.payment_period = module.payment_period
    state.formData.contract_no = module.contract_no
    state.formData.contract_date = module.contract_date
    state.formData.project_manager_uuid = module.project_manager_uuid
    state.formData.invoice_email_address = module.invoice_email_address
    state.formData.project_category_uuid = module.project_category_uuid
    state.formData.comment = module.comment
    state.formData.is_archived = module.is_archived
  },

  [Types.FILL_ARCHIVE_FORM_DATA](state, uuid) {
    const module = state.module['archived'].concat(state.module['active']).find(el => el.uuid === uuid)

    state.archiveFormData.uuid = module.uuid
    state.archiveFormData.pending_amounts = module.pending_amounts
    state.archiveFormData.warranty_period_starts_at = module.warranty_period_starts_at
    state.archiveFormData.warranty_period_ends_at = module.warranty_period_ends_at
    state.archiveFormData.bills_covered = module.bills_covered
  },

  [Types.SET_CURRENT_MODULE_EMPLOYEES](state, employees) {
    state.currentModule.employees = employees
    state.currentModule.employeesFetched = true
  },

  [Types.SET_CURRENT_MODULE_WAREHOUSE](state, tools) {
    state.currentModule.tools = tools
    state.currentModule.toolsFetched = true
  },

  [Types.PROJECT_UNASSIGN_EMPLOYEE](state, {employee, project}) {
    project.employees_no = 0 <= project.employees_no - 1 ? project.employees_no - 1 : 0

    if(state.isFetched) {
      const moduleIndex = state.module[project.is_archived ? 'archived' : 'active'].findIndex(el => el.uuid === project.uuid)

      if(0 <= moduleIndex)
        state.module[project.is_archived ? 'archived' : 'active'][moduleIndex] = project
    }

    if(state.currentModule?.uuid) {
      const currentModuleEmployeeIndex = state.currentModule?.employees.findIndex(el => el.uuid === employee.uuid)

      if(0 <= currentModuleEmployeeIndex)
        state.currentModule.employees.splice(currentModuleEmployeeIndex, 1)
    }
  },

  [Types.PROJECT_ASSIGN_EMPLOYEE](state, {employee, project}) {
    if(Object.keys(project).includes('employees_no'))
      project.employees_no = project.employees_no + 1

    if(state.isFetched) {
      const moduleIndex = state.module[project.is_archived ? 'archived' : 'active'].findIndex(el => el.uuid === project.uuid)

      if(0 <= moduleIndex)
        state.module[project.is_archived ? 'archived' : 'active'][moduleIndex] = project
    }

    if(state.currentModule?.uuid) {
      if(!Array.isArray(state.currentModule.employees))
        state.currentModule.employees = []

      const currentModuleEmployeeIndex = state.currentModule.employees.findIndex(currentModuleEmployee => currentModuleEmployee.uuid === employee.uuid)

      if(0 <= currentModuleEmployeeIndex)
        state.currentModule.employees[currentModuleEmployeeIndex] = employee
      else
        state.currentModule.employees.unshift(employee)
    }
  },

  [Types.SET_PERFORMED_TABLE_DATE](state, {year, month, weeks}) {
    state.performed_table.year = year
    state.performed_table.month = month
    state.performed_table.weeks = weeks
  },

  [Types.INITIATE_PERFORMED_TABLE](state) {
    state.showSearchResults = true

    state.moduleSearchResults = state.module.active.filter(({work_begins_month, work_ends_month}) => {
      let currentMonthDate = `${state.performed_table.year}-${padStart(state.performed_table.month, 2, '0')}`

      return work_begins_month <= currentMonthDate && currentMonthDate <= work_ends_month
    })
  },

  [Types.EXPORTING_PERFORMED_TABLE](state, status) {
    state.performed_table.exporting = !!status
  },

  [Types.SET_CURRENT_MODULE_ACCOMMODATIONS](state, accommodations) {
    state.currentModule.accommodations = accommodations
    state.currentModule.accommodationsFetched = true
  },

  [Types.PUSH_CURRENT_PROJECT_ACCOMMODATION](state, {event, data: accommodation}) {
    if(event === 'delete') {
      state.currentModule.accommodations = state.currentModule.accommodations.filter(({uuid}) => accommodation.uuid !== uuid)
      return
    }

    const index = state.currentModule.accommodations.findIndex(({uuid}) => uuid === accommodation.uuid)

    if(0 <= index) {
      state.currentModule.accommodations[index] = accommodation
      return
    }

    state.currentModule.accommodations.unshift(accommodation)
  },
}, [ArchivesMutations({module}), DocumentsMutations(module), DetailMutations({module}), TimekeepingMutations({module})])
