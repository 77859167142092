<template lang="pug">
div
  // Data fetch modal
  .data-fetch-progres(v-if="dataIsFetching")
    .progres-bar-wrapper
      circle-progress(
        :percent="dataPacketsLoaded / maxDataPackets * 100"
        :show-percent="true"
        :size="100"
        :border-width="10"
        :fill-color="'#003b4d'"
      )

  div(layout="horizontal")
    #layout-wrapper
      // Page top bar
      top-bar

      // Page main menu
      menu-row(
        v-if="authUser"
      )

      .main-content

        .page-content
          // Start Content
          .container-fluid
            router-view
        // Page main footer
        footer-row

  notification-wrapper

</template>

<script>
import {mapGetters} from "vuex";
import TopBar from "@/components/topBar/TopBar";
import MenuRow from "@/components/menu/MenuRow";
import FooterRow from "@/components/footer/FooterRow";
import NotificationWrapper from "@/components/modals/notification/NotificationWrapper";
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";

export default {
  name: 'CrmLayout',

  components: {
    NotificationWrapper,
    FooterRow,
    MenuRow,
    TopBar,
    CircleProgress
  },

  computed: {
    ...mapGetters({
      dataIsFetching: 'dataIsFetching',
      dataPacketsLoaded: 'dataPacketsLoaded',
      maxDataPackets: 'maxDataPackets',
      dataIsFetched: 'dataIsFetched',
      authUser: 'auth/authUser',
    })
  }
}
</script>