<template lang="pug">
footer.footer
  .container-fluid
    .row
      .col-sm-6 {{ currentYear }} &copy; Woorpla
      .col-sm-6
        .text-sm-end.d-none.d-sm-block  Develop by&nbsp;
          a(href="https://navus.lt" target="_blank") Navus
</template>

<script>
export default {
  name: "FooterRow",
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>

</style>