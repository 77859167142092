export default {
  serialize({getters}) {
    const formData = getters['getFormData']

    return {
      uuid: formData.uuid,
      first_name: formData.first_name,
      last_name: formData.last_name,
      country_id: formData.country_id,
      city_id: formData.city_id,
      address: formData.address,
      phone: formData.phone,
      email: formData.email,
      declared_address: formData.declared_address,
      positions_uuid: formData.positions_uuid ? Object.values(formData.positions_uuid) : undefined,
      has_driving_license: !!formData.has_driving_license,
      gender: formData.gender,
      citizenship_id: formData.citizenship_id,
      date_of_birth: formData.date_of_birth,
      languages: formData.languages,
      education: formData.education,
      qualification: formData.qualification,
      comment: formData.comment,
      channel: formData.channel,
      certificates: Array.isArray(formData?.certificates) ? formData.certificates : [],
    }
  },

  serializeBlacklist({getters}) {
    const formData = getters['getBlacklistFormData']

    return {
      uuid: formData.uuid,
      blacklisted_reason: formData.blacklisted_reason,
    }
  }
}

